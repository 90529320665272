import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "@app/core/services/notification.service";

@Component({
    selector: "sa-logout",
    template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
        <span> <a title="Sign Out"><i class="fa fa-sign-out"></i></a> </span>
    </div>
  `,
    styles: []
})

export class LogoutComponent implements OnInit {

    public user

    constructor(
        private _router: Router,
        private _notificationService: NotificationService
    ) { }

    showPopup() {
        this._notificationService.smartMessageBox(
            {
                title:
                    "<i class='fa fa-sign-out txt-color-orangeDark'></i> Logout?",
                content:
                    "You can improve your security further after logging out by closing this opened browser",
                buttons: "[No][Yes]"
            },
            ButtonPressed => {
                if (ButtonPressed == "Yes") {
                    this.logout();
                }
            }
        );
    }

    logout() {
        this._router.navigate(["/auth/login"]);
    }

    ngOnInit() { }
}
