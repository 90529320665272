import { Component, OnInit } from '@angular/core';
import { StorageService, AuthService } from '@app/core/services';
import { BriefUserModel } from '@app/core/models/user.models';

declare var $: any;

@Component({
    selector: 'sa-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
    user: BriefUserModel;
    userImageBackgroung: string;

    constructor(private _storage: StorageService,
        private _authService: AuthService) {
    }

    ngOnInit() {
        this.user = this._storage.getUser();
        if (!this.user) {
            this.logout();
        }
        if (this.user && this.user.image) {
            this.userImageBackgroung = "url(" + this.user.image + ")";
        }
        else {
            this.userImageBackgroung = "url('assets/img/avatars/sunny.png')";
        }
    }

    logout() {
        this._authService.logout();
    }
}
