import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { AuthLayoutComponent } from './shared/layout/app-layouts/auth-layout.component';
import { EmptyLayoutComponent } from './shared/layout/app-layouts/empty-layout.component';

const routes: Routes = [
	{
		path: '',
		component: MainLayoutComponent,
		data: { pageTitle: 'Valley Pay' },
		children: [
			{
				path: '',
				redirectTo: 'users',
				pathMatch: 'full'
			},
			{
				path: 'profile',
				loadChildren: './features/app-views/profile/profile.module#ProfileModule',
				data: { pageTitle: 'Profile' },
			},
			{
				path: 'users',
				loadChildren: './features/app-views/users/users.module#UsersModule',
				data: { pageTitle: 'Users' },
			},
			{
				path: 'transactions',
				loadChildren: './features/app-views/transactions/transactions.module#TransactionsModule',
				data: { pageTitle: 'Transactions' },
			},
			{
				path: 'exchange-rates',
				loadChildren: './features/app-views/exchange-rates/exchange-rates.module#ExchangeRatesModule',
				data: { pageTitle: 'Exchange rates' },
			}
		]
	},
	{
		path: 'auth',
		component: AuthLayoutComponent,
		loadChildren: './features/auth/auth.module#AuthModule'
	},
	{
		path: 'miscellaneous',
		component: EmptyLayoutComponent,
		loadChildren: './features/miscellaneous/miscellaneous.module#MiscellaneousModule',
	},
	{ path: '**', redirectTo: 'miscellaneous/not-found' }

];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
