import { Component, OnInit, OnDestroy } from '@angular/core';
import { StorageService } from './core/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'Valley Pay';

    constructor(
        private _storageService: StorageService,
        private _router: Router) {
    }

    ngOnInit() {
        if (!this._storageService.getToken()) {
            this._router.navigate(["/auth/login"]);
        }
    }

    ngOnDestroy(): void {
        
    }
}
