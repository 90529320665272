import { Injectable } from '@angular/core';
import { LoginResponseModel } from '../models/login.models';
import { BriefUserModel } from '../models/user.models';

export enum StorageKey {
    Token = "token",
    User = "user",
    Roles = "roles"
}

@Injectable()
export class StorageService {

    constructor() { }

    getToken(): string {
        let token: string = this.get(StorageKey.Token);
        if (!token || token === "") {
            return null;
        }
        return "Bearer " + token;
    }

    getUser(): BriefUserModel {
        var user: BriefUserModel = this.get(StorageKey.User);
        if (!user) {
            return null;
        }
        return user;
    }

    setLoginData(data: LoginResponseModel) {
        if (!data || !data.authToken)
            return;

        this.set(StorageKey.User, data.user);
        this.set(StorageKey.Token, data.authToken);
        this.set(StorageKey.Roles, data.role.split(','));
    }

    setUserData(user: BriefUserModel) {
        if (!user)
            return;
        
        this.set(StorageKey.User, user);
    }

    clearLoginData() {
        this.remove(StorageKey.Token);
        this.remove(StorageKey.User);
        this.remove(StorageKey.Roles);
    }

    private set(key: StorageKey, value) {
        if (!value) {
            return;
        }
        localStorage.setItem(key.toString(), JSON.stringify(value));
    }

    private get(key: StorageKey) {
        var jsonStr: string = localStorage.getItem(key.toString());
        if (!jsonStr) {
            return null;
        }
        return JSON.parse(jsonStr);
    }

    private clear() {
        localStorage.clear();
    }

    private remove(key: StorageKey) {
        localStorage.removeItem(key.toString());
    }
}

