export class ValidationModel {
    isValid(): boolean {
        if (this.modelError && this.modelError !== "") {
            return false;
        }
        if (this.errors && this.errors.length > 0) {
            return false;
        }
        return true;
    }
    clear() {
        this.modelError = null;
        this.errors = null;
    }
    modelError: string = null;
    errors: ValidationErrorModel[] = [];
}

export class ValidationErrorModel {
    key: string;
    message: string;
}