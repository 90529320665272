import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Http, ResponseContentType } from '@angular/http';
import { Observable, throwError, Subscriber } from 'rxjs';
import { ResponseModel } from '@app/core/models/base/response.model';
import { SuccessModel } from '@app/core/models/success.model';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from '.';
import { environment } from '../../../environments/environment';

export class ApiService {
    private readonly _apiUrl: string = environment.smartadmin.api;

    private _options = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Accept": "application/json",
            "Authorization": ""
        }
    }
    protected readonly _http: HttpClient;
    protected readonly _storage: StorageService;
    protected readonly _router: Router;

    constructor(http: HttpClient, storage: StorageService, router: Router) {
        this._http = http;
        this._storage = storage;
        this._router = router;
    }

    protected getApiUrl(apiRoute: string = "") {
        return this._apiUrl + apiRoute;
    }

    private getOptions() {
        var token = this._storage.getToken();
        this._options.headers.Authorization = token ? token : "";
        return this._options;
    }

    protected get<T>(apiRoute: string): Observable<ResponseModel<T>> {
        return this._http.get<ResponseModel<T>>(this.getApiUrl(apiRoute), this.getOptions()).pipe(catchError(error => {
            return this.errorHandler(error);
        }));
    }

    protected post<T>(apiRoute: string, body: {} = null): Observable<ResponseModel<T>> {
        return this._http.post<ResponseModel<T>>(this.getApiUrl(apiRoute), body, this.getOptions()).pipe(catchError(error => {
            return this.errorHandler(error);
        }));
    }

    protected put<T>(apiRoute: string, body: {} = null): Observable<ResponseModel<T>> {
        return this._http.put<ResponseModel<T>>(this.getApiUrl(apiRoute), body, this.getOptions()).pipe(catchError(error => {
            return this.errorHandler(error);
        }));
    }

    protected delete(apiRoute: string): Observable<ResponseModel<SuccessModel>> {
        return this._http.delete<ResponseModel<SuccessModel>>(this.getApiUrl(apiRoute), this.getOptions()).pipe(catchError(error => {
            return this.errorHandler(error);
        }));
    }

    private errorHandler(error: HttpErrorResponse) {
        console.log("errorHandler");
        console.log(error);
        var responseModel: ResponseModel<any> = new ResponseModel<any>();
        try {
            responseModel = error.error as ResponseModel<any>;
            if (responseModel.statusCode === 401) {
                console.log("errorHandler statusCode === 401");
                this._router.navigate(["auth/login"]);
            }
            if (responseModel.statusCode === 500 || responseModel.statusCode === 403) {
                console.log("errorHandler statusCode === 500");
                this._router.navigate(["miscellaneous/error"]);
            }
            return new Observable<ResponseModel<any>>(data => data.next(responseModel));
        }
        catch (err) {
            console.log("errorHandler-catch");
            console.log(err);
            //this._router.navigate(["auth/login"]);
            return throwError(error);
        }
    }
}