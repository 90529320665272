import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

import { ApiService } from "./apiservise.abstract";
import { StorageService } from "./storage.service";
import { BriefUserModel, UpdateProfileRequestModel } from "../models/user.models";
import { ValidationModel } from "../models/app.model";
import { ResponseModel } from "../models/base/response.model";
import { SuccessModel } from "../models/success.model";
import { Router } from "@angular/router";


const defaultUser: BriefUserModel = {
    email: "",
    phone: "",
    firstName: "Guest",
    lastName: "",
    image: "assets/img/avatars/sunny.png"
}

@Injectable()
export class UserService extends ApiService {
    private readonly _route: string = "user"

    constructor(http: HttpClient, storage: StorageService, router: Router) {
        super(http, storage, router);
    }

    getUser(): BriefUserModel {
        var userStorage = this._storage.getUser();
        return userStorage ? userStorage : defaultUser;
    }

    updateUserProfile(model: UpdateProfileRequestModel): Observable<ResponseModel<BriefUserModel>> {
        return this.put<BriefUserModel>("account/profile", model);
    }

    updateDeviceToken(token: string): Observable<ResponseModel<SuccessModel>> {
        var query = this._route + "/deviceToken?token=" + token;
        return this.put<SuccessModel>(query, null);
    }

    validateUpdateUserProfileModel(model: UpdateProfileRequestModel): ValidationModel {
        var result: ValidationModel = new ValidationModel();
        if (!model) {
            result.modelError = "Model is null";
            return result;
        }
        if (!model.firstName || model.firstName === "") {
            result.errors.push({ key: "firstName", message: "First name is required field" });
        }

        if (!model.lastName || model.lastName === "") {
            result.errors.push({ key: "lastName", message: "Last name is required field" });
        }

        if (model.firstName && model.firstName.length > 40) {
            result.errors.push({ key: "firstName", message: "Character limit exceeded (max length 40)" });
        }

        if (model.lastName && model.lastName.length > 40) {
            result.errors.push({ key: "lastName", message: "Character limit exceeded (max length 40)" });
        }

        return result;
    }
}
