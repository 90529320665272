import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { ResponseModel } from '../models/base/response.model';
import { LoginResponseModel, LoginRequestModel } from '../models/login.models';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SuccessModel } from '../models/success.model';
import { ApiService } from './apiservise.abstract';
import { PasswordChangeModel, PasswordForgotModel } from '../models/password.models';
import { ValidationModel } from '../models/app.model';


@Injectable()
export class AuthService extends ApiService {
    
    private readonly _route: string = "account";

    readonly loginRoute: string = "/auth/login";

    constructor(http: HttpClient, storage: StorageService, router: Router) {
        super(http, storage, router);
    }

    login(model: LoginRequestModel): Observable<ResponseModel<LoginResponseModel>> {
        model.osType = "Unknown";
        model.deviceId = "webadmin";
        model.deviceToken = "";
        return this.post<LoginResponseModel>("account/login", model);
    }

    changePassword(model: PasswordChangeModel): Observable<ResponseModel<SuccessModel>> {
        var query = this._route + "/change-password";
        return this.post<SuccessModel>(query, model);
    }

    forgotPassword(model: PasswordForgotModel): Observable<ResponseModel<SuccessModel>> {
        var query = this._route + "/forgot";
        return this.post<SuccessModel>(query, model);
    }

    logout(): void {
        var query = this._route + "/logout";
        this.post<SuccessModel>(query).subscribe(_ => _);
        this._storage.clearLoginData();
        this._router.navigate(["auth/login"]);
    }

    validatePasswordChangeModel(model: PasswordChangeModel): ValidationModel {
        var result: ValidationModel = new ValidationModel();
        if (!model) {
            result.modelError = "Model is null";
            return result;
        }

        if (!model.currentPassword || model.currentPassword === "") {
            result.errors.push({ key: "currentPassword", message: "Current password is required field" });
        }

        if (!model.password || model.currentPassword === "") {
            result.errors.push({ key: "password", message: "Password is required field" });
        }

        if (model.password && model.password.includes(" ")) {
            result.errors.push({ key: "password", message: "Invalid character - ' '" });
        }

        if (!result.isValid()) {
            return result;
        }

        if (model.password.length > 30) {
            result.errors.push({ key: "password", message: "Character limit exceeded (max length 30)" });
        }

        if (model.password.length < 6) {
            result.errors.push({ key: "password", message: "Minimum password size 6 characters" });
        }

        if (model.password !== model.confirmPassword) {
            result.errors.push({ key: "confirmPassword", message: "Passwords do not match" });
        }

        return result;
    }

    validateLoginModel(model: LoginRequestModel): ValidationModel {
        var result: ValidationModel = new ValidationModel();
        if (!model) {
            result.modelError = "Model is null";
            return result;
        }

        if (!model.phone || model.phone === "") {
            result.errors.push({ key: "phone", message: "Phone number is required" });
        }

        if (!model.password || model.password === "") {
            result.errors.push({ key: "password", message: "Password is required" });
        }

        if (!result.isValid()) {
            return result;
        }

        if (!this.isPhoneNumber(model.phone)) {
            result.errors.push({ key: "phone", message: "Please enter a VALID phone number" });
        }
        
        //if (!this.isEmail(model.email)) {
        //    result.errors.push({ key: "email", message: "Please enter a VALID email address" });
        //}

        return result;
    }

    isEmail(email: string): boolean {
        var re = /^.+@.+\.{1}\w+$/gi;
        var isEmail = email.search(re);
        return isEmail === 0;
    }

    isPhoneNumber(phone: string): boolean {
        return true;
    }
}
