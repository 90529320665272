import {Component} from '@angular/core';
import { LayoutService } from '@app/core/services';

@Component({
  selector: 'sa-minify-menu',
  template: `<span class="minifyme" (click)="toggle()">
    <i class="fa fa-arrow-circle-left hit"></i>
</span>`,
})
export class MinifyMenuComponent {

    constructor(private _layout: LayoutService) {}

    toggle() {
        this._layout.onMinifyMenu();
  }
}
