import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'sa-recent-projects',
    templateUrl: './recent-projects.component.html'
})
export class RecentProjectsComponent implements OnInit {

    constructor() {
        
    }

    ngOnInit() {
        
    }
}
