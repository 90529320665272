import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IonicStorageModule } from "@ionic/storage";

import { services } from '@app/core/services/index'
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { TokenInterceptor } from './services/token.interceptor';


@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		IonicStorageModule.forRoot()
	],
	exports: [],
	providers: [
		...services,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		}
	]
})
export class CoreModule {
	constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
	throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}
}
