import { Component, OnInit } from '@angular/core';

@Component({

    selector: 'sa-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        
    }
}
